import React, { useState, useEffect, ChangeEvent } from "react";
import { Device } from "../../features/devices/deviceSlice";
import ToggleSwitch from "../../utils/ToggleSwitch";
import { useGetGroupQuery } from "../../services/groupAPI";
import { mapObjectToArray } from "../../utils/GroupNotificationUtils";
import { useUpdateDeviceSettingsMutation } from "../../services/deviceAPI";
import { toast } from "react-toastify";
import { Tooltip } from "flowbite-react";
import useGetLanguage from "../../utils/useGetLanguage";
import { LanguageText } from "../../dictionary/DeviceText";

interface EditDeviceSettingsProps {
  device: Device | null;
}

const EditDeviceSettings = ({ device }: EditDeviceSettingsProps): JSX.Element => {
  const language: LanguageText = useGetLanguage({
    fileName: "DeviceText",
  });
  const [updateSettings, { isSuccess, isLoading, isError, error }] =
    useUpdateDeviceSettingsMutation();
  const [isNotificationOn, setIsNotificationOn] = useState<boolean>(false);
  const [recipientID, setRecipientID] = useState<string>("");

  const groupID = device?.group_id;
  const {
    data: group,
    isLoading: isGroupLoading,
    error: groupError,
  } = useGetGroupQuery({ id: String(groupID) });

  const recipients = mapObjectToArray(group?.users || {}).filter(
    (user) =>
      user?.permissions?.can_view_devices?.can_view_all ||
      user?.permissions?.can_view_devices?.can_view_any
  );

  const isToggleSwitchDisabled: boolean =
    !device?.settings?.offline_notification_recipient_id || !recipientID;
  const isSaveBtnDisabled: boolean =
    recipientID === "" ||
    recipientID === device?.settings?.offline_notification_recipient_id;

  const handleRecipientChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setRecipientID(event.target.value);
  };

  const handleToggleNotification = async () => {
    // If notification is being turned on but no recipient is selected
    if (!isNotificationOn && !recipientID) {
      toast.warning(
        language?.editDevice?.editDeviceSettings?.tooltip?.notificationDisabledMsg ||
          "Please select a recipient before turning on notifications"
      );
      return;
    }

    const updatedDevice = {
      ...device,
      settings: {
        ...device?.settings,
        offline_notification_active: !isNotificationOn,
      },
    };

    try {
      await saveSettings(updatedDevice);
      setIsNotificationOn((prev) => !prev);
    } catch (error) {
      console.error("Error updating device settings", error);
      toast.error("Failed to update device settings");
    }
  };

  const handleSaveRecipient = async () => {
    if (recipientID === device?.settings?.offline_notification_recipient_id) {
      return;
    }

    const updatedDevice = {
      ...device,
      settings: {
        ...device?.settings,
        offline_notification_recipient_id: recipientID,
      },
    };

    try {
      await saveSettings(updatedDevice);
    } catch (err) {
      console.error("Error saving recipient", err);
      toast.error(
        language?.editDevice?.editDeviceSettings?.toast?.failedUpdateMsg ||
          "Failed to update recipient."
      );
    }
  };

  const saveSettings = async (updatedDevice: any) => {
    try {
      await updateSettings({
        deviceID: device?.id || "",
        deviceSettings: updatedDevice.settings,
        language: language,
      });
    } catch (err) {
      console.log("Failed to update device settings");
    }
  };

  const renderRecipientOptions = () => {
    if (recipients.length === 0) {
      return (
        <option value="">
          {language?.editDevice?.editDeviceSettings?.noRecipientFoundMsg ||
            "No recipient found"}
        </option>
      );
    }
    return recipients.map((recipient) => (
      <option key={recipient.user_id} value={recipient.user_id}>
        {recipient.username}
      </option>
    ));
  };

  useEffect(() => {
    if (device) {
      setIsNotificationOn(device.settings?.offline_notification_active || false);
      setRecipientID(device.settings?.offline_notification_recipient_id || "");
    }
  }, [device]);
  return (
    <div className="w-full max-w-2xl p-6 bg-white border rounded-lg shadow-md ml-0 mb-5 mt-5">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h2 className="text-xl font-semibold text-gray-800">
            {language?.editDevice?.editDeviceSettings?.heading ||
              "Device offline notification"}
          </h2>
          <p className="text-base text-gray-500">
            {language?.editDevice?.editDeviceSettings?.subHeading ||
              "Email notification when a device has been offline for 24 hours"}
          </p>
        </div>
        {isToggleSwitchDisabled ? (
          <Tooltip
            content={
              language?.editDevice?.editDeviceSettings?.tooltip
                ?.notificationDisabledMsg || "Add a recipient to activate"
            }
            placement="top-end"
            style="light"
            trigger="hover"
          >
            <ToggleSwitch
              isActive={isNotificationOn}
              onToggle={handleToggleNotification}
              isDisabled={isToggleSwitchDisabled}
            />
          </Tooltip>
        ) : (
          <ToggleSwitch isActive={isNotificationOn} onToggle={handleToggleNotification} />
        )}
      </div>

      <div className="flex flex-col sm:flex-row items-start sm:items-center">
        <label className="text-base font-medium text-gray-700 sm:mr-4 mb-2 sm:mb-0">
          {language?.editDevice?.editDeviceSettings?.recipientSelectlabel ||
            "Send email to"}
        </label>
        <select
          className="flex-grow border border-gray-300 rounded p-3 text-base mb-2 sm:mb-0"
          name="selectInput"
          value={recipientID}
          onChange={handleRecipientChange}
        >
          <option value="">
            {language?.editDevice?.editDeviceSettings?.recipientSelectDefaultOption ||
              "Select a Recipient"}
          </option>
          {renderRecipientOptions()}
        </select>
        <button
          disabled={isSaveBtnDisabled}
          className="bg-blue-500 hover:bg-blue-700 disabled:cursor-not-allowed disabled:opacity-50 text-white text-base font-bold py-2 px-4 rounded-md shadow-md transition-transform duration-300 ease-in-out transform hover:scale-105 ml-0 sm:ml-4"
          onClick={handleSaveRecipient}
        >
          {language?.editDevice?.editDeviceSettings?.saveRecipientBtn || "Save"}
        </button>
      </div>
    </div>
  );
};

export default EditDeviceSettings;
