import React, { useState, useEffect } from "react";
import {
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
  useAcknowledgeMutation,
  useDeleteNotificationMutation,
} from "../../services/groupNotificationAPI";
import { useParams, useNavigate } from "react-router-dom";
import { Table } from "../../utils/Table";
import { IoMdSettings } from "react-icons/io";
import { ColumnDef } from "@tanstack/react-table";
import {
  NotificationState,
  selectNotifications,
} from "../../features/notifications/notificationSlice";
import { Button } from "../../components/Button";
import { IoAddCircle } from "react-icons/io5";
import { RiDeleteBinFill } from "react-icons/ri";
import { GetGroupUserInfo } from "../../utils/GroupNotificationUtils";
import ToggleSwitch from "../../utils/ToggleSwitch";
import DotStatus from "../../components/groupNotifications/DotStatus";
import Prompt from "../../utils/Prompt";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import useGetLanguage from "../../utils/useGetLanguage";
import { selectUser } from "../../features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { UserPermissions } from "../../features/groups/groupSlice";
import { selectGroups } from "../../features/groups/groupSlice";
import { toast } from "react-toastify";

const GroupNotificationsIndex = (): JSX.Element => {
  const language: LanguageText = useGetLanguage({
    fileName: "GroupNotificationText", 
  });
  const [
    updateNotification,
    { isError, error: updateNotificationError, data, isSuccess: isUpdateSuccess },
  ] = useUpdateNotificationMutation();

  const [
    acknowledge,
    {
      isError: isAcknowledgeError,
      error: acknowledgeError,
      data: acknowledgeData,
      isSuccess: isAcknowledgeSuccess,
    },
  ] = useAcknowledgeMutation();

  let navigate = useNavigate();
  const { groupid } = useParams<{ groupid: string }>();
  const userGroupPermissions: UserPermissions =
    useAppSelector(selectUser)?.groups[`${groupid}`]?.permissions;
  const numOfNotificationsAllowed: any =
    useAppSelector(selectGroups)?.groups[`${groupid}`]?.subscription?.perks
      ?.notification_alerts;

  const handleToggleSwitchChange = async (notification: any) => {
    const updatedNotification: NotificationState = {
      ...notification,
      status: {
        ...notification.status,
        active: !notification.status.active,
      },
    };
    updatedNotification &&
      (await updateNotification({ notification: updatedNotification, language }));
  };
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [isDelete, setIsDelete] = useState<boolean | null>(null);

  const [showAcknowledgePrompt, setShowAcknowledgePrompt] = useState<boolean>(false);
  const [isAcknowledge, setIsAcknowledge] = useState<boolean | null>(null);

  const handleCloseDeletePrompt = (isDelete: boolean) => {
    setIsDelete(isDelete);
    setShowDeletePrompt(false);
  };

  const handleCloseAcknowledgePrompt = (isAcknowledge: boolean) => {
    setIsAcknowledge(isAcknowledge);
    setShowAcknowledgePrompt(false);
  };

  const handleDeleBtnClick = (id: string) => {
    setShowDeletePrompt(true);
    id !== "" && setDeleteNotificationId(id);
  };

  const handleAcknowledgeBtnClick = (notificationId: string) => {
    setShowAcknowledgePrompt(true);
    notificationId && setAcknowledgeNotificationId(notificationId);
  };

  const {
    data: groupNotificationData,
    isLoading,
    error,
  } = useGetNotificationsQuery(groupid, {
    skip: !userGroupPermissions?.can_view_notifications, // This would skip the data fetch if user does not have view permission
  });

  const numOfNotifications = groupNotificationData?.length;
  const handleAddBtnClick = () => {
    if (numOfNotifications >= numOfNotificationsAllowed) {
      toast.error(
        language?.messages?.toast?.createNotification?.maxNotification ||
          "You have reached the maximum number of notifications your plan allows. Please review you subscription"
      );
      return;
    }
    navigate(`/group/${groupid}/notifications/create`);
  };

  const [deleteNotificationId, setDeleteNotificationId] = useState<string>("");
  const [acknowledgeNotificationId, setAcknowledgeNotificationId] = useState<string>("");

  const [
    deleteNotification,
    {
      isLoading: isDeleteNotificationLoading,
      isError: isDeleteNotificationError,
      error: deleteNotificationError,
    },
  ] = useDeleteNotificationMutation();

  const columns: ColumnDef<NotificationState>[] = [
    {
      accessorFn: (row) => (row.name ? row.name : "No name set"),
      id: "Notification Name",
      cell: ({ row, getValue }) => {
        const notificationid: any = row.original.id;
        return (
          <div className="flex ">
            {userGroupPermissions?.can_edit_notifications && (
              <IoMdSettings
                title="Title"
                size={16}
                className="cursor-pointer hover:fill-black"
                onClick={() =>
                  navigate(`/group/${groupid}/notifications/${notificationid}/update`)
                }
              />
            )}
            <div className="ml-2">{getValue() as string}</div>
          </div>
        );
      },
      header: language?.notificationsTable?.columns?.notificationName || "Name",
    },
    {
      accessorFn: (row) => row.users,
      id: "Recipient",
      cell: ({ row, getValue }) => {
        const value = getValue();
        if (Array.isArray(value)) {
          return (
            <div className="flex flex-wrap">
              {value.map((userid, index) => {
                const { data, isLoading, error } = GetGroupUserInfo({
                  groupid: groupid || "",
                  userid: userid,
                });
                return (
                  <div key={index} className="ml-2">
                    {index < value.length - 1
                      ? `${data ? data.email : "Unknown"},`
                      : `${data ? data.email : "Unknown"}`}
                  </div>
                );
              })}
            </div>
          );
        }
        // Check if there is no recipient and render no recipient
        if (!value) {
          return (
            <div className="flex">
              <div className="ml-2">{`No recipients`}</div>
            </div>
          );
        }
        // Handle case where value is not an array (if needed)
        return (
          <div className="flex">
            <div className="ml-2">{value as string}</div>
          </div>
        );
      },
      header: language?.notificationsTable?.columns?.recipients || "Recipients",
    },
    {
      accessorFn: (row) => row,
      id: "Enable",
      cell: ({ row, getValue }) => {
        const notification: any = getValue();
        return userGroupPermissions?.can_edit_notifications ? (
          <ToggleSwitch
            isActive={notification?.status?.active}
            onToggle={() => handleToggleSwitchChange(notification)}
          />
        ) : (
          <DotStatus isActive={notification?.status?.active} />
        );
      },
      header: language?.statusComponent?.active || "Active",
    },
    {
      accessorFn: (row) => row,
      id: "status",
      cell: ({ row, getValue }) => {
        const notification: any = getValue();

        const formatDate = (isoString: string): string => {
          const date: Date = new Date(isoString);
          const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          };
          return date.toLocaleString("en-GB", options);
        };

        return (
          <div className="flex items-center space-x-4 p-4 w-full max-w-md">
            <div
              className={`w-2 h-2 rounded-full bg-${
                notification?.status?.current ? "green" : "red"
              }-500`}
            >
              {" "}
            </div>
            {notification?.status?.current &&
              (notification?.configuration?.latch_on ? (
                <div className="leading-none">
                  <p className="text-xl text">
                    {formatDate(notification?.status?.triggered_at)}
                  </p>
                  <p className="ml-0.5">
                    {language?.general?.triggeredAt || "Triggered at"}
                  </p>
                </div>
              ) : (
                <div className="leading-none">
                  <p className="text-xl text">
                    {formatDate(notification?.status?.evaluated_at)}
                  </p>
                  <p className="ml-0.5">
                    {language?.general?.triggeredAt || "Triggered at"}
                  </p>
                </div>
              ))}
          </div>
        );
      },
      header: "Status",
    },
    {
      accessorFn: (row) => row.id,
      id: "acknowledge",
      cell: ({ row, getValue }) => {
        const notification: any = row.original;
        return (
          <div className="w-fit ml-2">
            {userGroupPermissions?.can_edit_notifications &&
              notification?.configuration?.latch_on && (
                <button
                  className={`bg-blue-500 text-white font-bold py-2 px-4  mt-5 mb-0 ml-5 rounded-md shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 active:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
                  onClick={() => handleAcknowledgeBtnClick(notification.id)}
                >
                  {language?.general?.acknowledge || "Acknowledge"}
                </button>
              )}
          </div>
        );
      },
      header: language?.general?.acknowledge || "Acknowledge",
    },
    {
      accessorFn: (row) => row.id,
      id: "delete",
      cell: ({ row, getValue }) => {
        const id: any = row.original.id;
        return (
          <div className="w-fit ml-2">
            {userGroupPermissions?.can_edit_notifications && (
              <button
                className={`bg-red-700 text-white font-bold py-2 px-4 rounded-md
              transition-colors duration-200 shadow-sm 
              hover:bg-red-700 focus:bg-red-800 focus:outline-non`}
                onClick={() => handleDeleBtnClick(id)}
              >
                {language?.general?.delete || "Delete"}
              </button>
            )}
          </div>
        );
      },
      header: language?.notificationsTable?.columns?.delete || "Delete",
    },
  ];

  const myTable = Table(
    groupNotificationData,
    columns,
    language?.notificationsTable?.title || "Notifications",
    language?.notificationsTable?.noNotificationMessage ||
      "No Notifications to display at the moment"
  );

  useEffect(() => {
    if (isDelete) {
      deleteNotification({ notificationID: deleteNotificationId, language });
      setIsDelete(false);
    }
  }, [isDelete]);

  useEffect(() => {
    if (isAcknowledge) {
      acknowledge({ notificationId: acknowledgeNotificationId, language });
      setIsAcknowledge(false);
    }
  }, [isAcknowledge]);

  return (
    <>
      {showDeletePrompt && (
        <Prompt
          onClose={handleCloseDeletePrompt}
          title={language?.general?.promptTitle || "Are you Sure?"}
          confirmText={language?.general?.delete || "Delete"}
          confirmBtnColor="red"
          cancelText={language?.general?.cancel || "Cancel"}
        />
      )}
      {showAcknowledgePrompt && (
        <Prompt
          onClose={handleCloseAcknowledgePrompt}
          title={language?.general?.promptTitle || "Are you Sure?"}
          confirmText={language?.general?.acknowledge || "Aknowledge"}
          confirmBtnColor="blue"
          cancelText={language?.general?.cancel || "Cancel"}
        />
      )}
      {userGroupPermissions?.can_edit_notifications && (
        <button
          className="bg-blue-500 text-white font-bold py-2 px-4  mt-5 mb-0 ml-5 rounded-md shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 active:scale-95 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          onClick={handleAddBtnClick}
        >
          {language?.general?.addNotification || "Add Notification"}
        </button>
      )}

      {myTable}
    </>
  );
};

export default GroupNotificationsIndex;
