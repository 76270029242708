import React, { useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectNotifications } from "../../features/notifications/notificationSlice";
import { useUpdateNotificationMutation } from "../../services/groupNotificationAPI";
import NotificationName, {
  NotificationNameRef,
} from "../../components/groupNotifications/NotificationName";
import NotificationRule, {
  NotificationRuleRef,
} from "../../components/groupNotifications/NotificationRule";
import NotificationEmail, {
  NotificationEmailRef,
} from "../../components/groupNotifications/NotificationEmail";
import NotificationConfiguration, {
  NotificationConfigRef,
} from "../../components/groupNotifications/NotificationConfiguration";
import NotificationStatus, {
  NotificationStatusRef,
} from "../../components/groupNotifications/NotificationStatus";
import NotificationDashboard, {
  NotificationDashboardRef,
} from "../../components/groupNotifications/NotificationDashboard";
import { mapObjectToArray } from "../../utils/GroupNotificationUtils";
import { toast } from "react-toastify";
import { convertNotificationRulesArrayIntoMap } from "../../utils/GroupNotificationUtils";
import { NotificationState } from "../../features/notifications/notificationSlice";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import useGetLanguage from "../../utils/useGetLanguage";

const UpdateGroupNotification = () => {
  const language: LanguageText = useGetLanguage({
    fileName: "GroupNotificationText",
  });

  const { groupid, notificationid } = useParams();
  const navigate = useNavigate();

  const [
    updateNotification,
    {
      isLoading,
      isError,
      error: updateNotificationError,
      data,
      isSuccess: isUpdateSuccess,
    },
  ] = useUpdateNotificationMutation();

  const notificationNameRef = useRef<NotificationNameRef>(null);
  const notificationEmailRef = useRef<NotificationEmailRef>(null);
  const notificationRuleRef = useRef<NotificationRuleRef>(null);
  const notificationDashboardRef = useRef<NotificationDashboardRef>(null);
  const notificationConfigRef = useRef<NotificationConfigRef>(null);
  const notificationStatusRef = useRef<NotificationStatusRef>(null);

  const currentNotification = useAppSelector(selectNotifications).find(
    (notification) => notification.id === notificationid
  );
  const handleUpdate = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const errorMessage =
      language?.messages?.toast?.general?.notificationError || "Some fields are required";

    const notification: any = { ...currentNotification };
    const notificationName = notificationNameRef.current;
    const notificationRule = notificationRuleRef.current;
    const notificationDashboard = notificationDashboardRef.current;

    const notificationEmail = notificationEmailRef.current;
    const notificationConfig = notificationConfigRef.current;
    const notificationStatus = notificationStatusRef.current;

    if (notificationName) {
      if (!notificationName.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { name } = notificationName.getNotificationName();
      notification.name = name;
    }

    if (notificationEmail) {
      if (!notificationEmail.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { subject, body, users, trigger } = notificationEmail.getNotificationEmail();
      notification.email = { subject, body, trigger };
      notification.users = users;
    }

    if (notificationRule) {
      if (!notificationRule.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { rules } = notificationRule.getNotificationRule();
      rules && (notification.rules = rules);
    }

    if (notificationDashboard) {
      if (!notificationDashboard.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { dashboard } = notificationDashboard.getNotificationDashboard();
      notification.dashboard = dashboard;
    }

    if (notificationConfig) {
      if (!notificationConfig.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { configuration } = notificationConfig.getNotificationConfig();
      configuration && (notification.configuration = configuration);
    }

    if (notificationStatus) {
      if (!notificationStatus.validate()) {
        toast.error(errorMessage);
        return;
      }
      const { status } = notificationStatus.getNotificationStatus();
      status && (notification.status = status);
    }

    const updatedNotification: NotificationState =
      convertNotificationRulesArrayIntoMap(notification);
    updatedNotification &&
      (await updateNotification({ notification: updatedNotification, language }));
  };

  useEffect(() => {
    (isUpdateSuccess || isError) && navigate(`/group/${groupid}/notifications`);
  }, [isUpdateSuccess, isError, groupid, navigate]);

  return (
    <>
      <div className="bg-gray-100 p-8 w-full">
        <div className="bg-white p-8 rounded-lg shadow-md w-full">
          <NotificationName name={currentNotification?.name} ref={notificationNameRef} />
          <NotificationRule
            ref={notificationRuleRef}
            notificationRules={
              currentNotification?.rules && mapObjectToArray(currentNotification.rules)
            }
          />
          <NotificationDashboard
            ref={notificationDashboardRef}
            dashboard={currentNotification?.dashboard}
          />
          <NotificationEmail
            ref={notificationEmailRef}
            users={currentNotification?.users ? currentNotification.users : []}
            subject={`${currentNotification?.email.subject}`}
            body={`${currentNotification?.email.body}`}
            trigger=""
          />
          <hr className="my-4 border-1" />
          <NotificationConfiguration
            ref={notificationConfigRef}
            configuration={currentNotification?.configuration}
          />
          <hr className="my-4 border-1" />
          <NotificationStatus
            status={currentNotification?.status}
            ref={notificationStatusRef}
          />
          <hr className="my-4 border-1" />
          <div className="flex">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-4 rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400"
              onClick={handleUpdate}
            >
              {language?.general?.updateNotification || "Update Notification"}
            </button>
            <button
              className="bg-gray-300 hover:bg-gray-500 text-black-700 font-bold py-2 px-4 rounded-md  shadow-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={() => navigate(`/group/${groupid}/notifications`)}
            >
              {language?.general?.cancel || "Cancel"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateGroupNotification;
