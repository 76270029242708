import { I18nSettings } from "fecha";

type format = (date: Date, format?: string, i18n?: I18nSettings) => string;

export function DataTimeToLocale(date: Date): string {
  const format: format = require("fecha").format;
  return format(date, "YYYY-MM-DD HH:mm:ss");
}

export const convertSecondsToDays = (seconds: number) => {
  seconds = Math.abs(Math.floor(seconds));
  const secondsPerMinute = 60;
  const secondsPerHour = 3600;
  const secondsPerDay = 86400;

  const days = Math.floor(seconds / secondsPerDay);
  seconds %= secondsPerDay;

  const hours = Math.floor(seconds / secondsPerHour);
  seconds %= secondsPerHour;

  const minutes = Math.floor(seconds / secondsPerMinute);
  seconds %= secondsPerMinute;

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

export const convertUtcToLocalTime = (utcTime: Date | string): string => {
  const date = utcTime instanceof Date ? utcTime : new Date(utcTime);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const localDateString = `${hours}:${minutes} ${day}-${month}-${year}`;
  return localDateString;
};
