import { useState, useEffect } from "react";
import { useAppSelector } from "../app/hooks";
import { selectLanguage } from "../features/user/userSlice";
interface UseGetLanguageProps {
  fileName: string;
}

const useGetLanguage = ({ fileName }: UseGetLanguageProps) => {
  const stateLang = useAppSelector(selectLanguage);
  const [language, setLanguage] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const loadDictionaries = async () => {
    try {
      const dictionary = await import(`../dictionary/${fileName}`);
      const languageKey = stateLang.charAt(0).toUpperCase() + stateLang.slice(1);
      if (dictionary[languageKey]) {
        setLanguage(dictionary[languageKey]);
      } else {
        console.error(
          `Language '${languageKey}' not found in ${fileName} dictionary. Falling back to English.`
        );
        setLanguage(dictionary["English"] || null); // Fallback to English
      }
    } catch (error) {
      console.error("Error loading dictionaries:", error);
      setLanguage(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadDictionaries();
  }, [stateLang, fileName]);

  if (isLoading) {
    return null;
  }

  return language;
};

export default useGetLanguage;
