export type LanguageText = {
  settings: {
    changeYourDetails: string;
    changeYourPassword: string;
    labels: {
      username: string;
      emailAddress: string;
      oldPassword: string;
      newPassword: string;
      confirmNewPassword: string;
      language: string;
    };
    buttons: {
      save: string;
      change: string;
      deleteUser: string;
    };
    toasts: {
      updateUser: string;
      deleteUser: string;
      changePassword: string;
      updateUserSettings: string;
      updateUserCheckEmail: string;
    };
    modals: {
      changeDetailsModal: {
        title: string;
        body: {
          passwordRequired: string;
        };
        labels: {
          password: string;
        };
        buttons: {
          accept: string;
          cancel: string;
        };
      };
      deleteModal: {
        title: string;
        body: {
          phrase1: React.ReactNode;
        };
        labels: {
          password: string;
        };
        buttons: {
          accept: string;
          cancel: string;
        };
      };
    };
  };
  notifications: {
    title: string;
    subTitle: string;
    markAsRead: string;
    markAsUnread: string;
    markAllAsRead: string;
    emptyNotificationMsg: string;
    footerMsg: string;
    toast: {
      success: string;
      error: string;
    };
  };
};
export const English: LanguageText = {
  settings: {
    changeYourDetails: "Change your details",
    changeYourPassword: "Change your password",
    labels: {
      username: "Username",
      emailAddress: "Email Address",
      oldPassword: "Old Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
      language: "Language",
    },
    buttons: {
      save: "Save",
      change: "Change",
      deleteUser: "Delete User",
    },
    toasts: {
      updateUser: "User updated successfully",
      deleteUser: "User deleted successfully",
      changePassword: "Password changed successfully",
      updateUserSettings: "User settings updated successfully",
      updateUserCheckEmail:
        "If you have changed your email address, please check your email to confirm the change.",
    },
    modals: {
      changeDetailsModal: {
        title: "Password Required",
        body: {
          passwordRequired: "Password Required for Username and Email changes.",
        },
        labels: {
          password: "Password",
        },
        buttons: {
          accept: "Submit",
          cancel: "Cancel",
        },
      },
      deleteModal: {
        title: "Delete Account",
        body: {
          phrase1: (
            <>
              , are you sure you want to <b>delete</b> your account? This action cannot be
              undone.
            </>
          ),
        },
        labels: {
          password: "Password",
        },
        buttons: {
          accept: "Delete Account",
          cancel: "Cancel",
        },
      },
    },
  },
  notifications: {
    title: "Notifications",
    subTitle: "Latest",
    markAsRead: "Mark as read",
    markAsUnread: "Mark as unread",
    markAllAsRead: "Mark all as read",
    emptyNotificationMsg: "No notifications found",
    footerMsg: "That's all your notifications from the last 30 days",
    toast: {
      success: "Update successful",
      error: "Request failed. Please try again later",
    },
  },
};
export const French: LanguageText = {
  settings: {
    changeYourDetails: "Changez vos coordonnées",
    changeYourPassword: "Changez votre mot de passe",
    labels: {
      username: "Nom d'utilisateur",
      emailAddress: "Adresse e-mail",
      oldPassword: "Ancien mot de passe",
      newPassword: "Nouveau mot de passe",
      confirmNewPassword: "Confirmer le nouveau mot de passe",
      language: "Langue",
    },
    buttons: {
      save: "Sauvegarder",
      change: "Changement",
      deleteUser: "Supprimer l'utilisateur",
    },
    toasts: {
      updateUser: "Utilisateur mis à jour avec succès",
      deleteUser: "Utilisateur supprimé avec succès",
      changePassword: "Le mot de passe a été changé avec succès",
      updateUserSettings:
        "Les paramètres de l'utilisateur ont été mis à jour avec succès",
      updateUserCheckEmail:
        "Si vous avez modifié votre adresse e-mail, veuillez vérifier votre e-mail pour confirmer le changement.",
    },
    modals: {
      changeDetailsModal: {
        title: "Mot de passe requis",
        body: {
          passwordRequired:
            "Mot de passe requis pour les modifications de nom d'utilisateur et d'e-mail.",
        },
        labels: {
          password: "Mot de passe",
        },
        buttons: {
          accept: "Soumettre",
          cancel: "Annuler",
        },
      },
      deleteModal: {
        title: "Supprimer le compte",
        body: {
          phrase1: (
            <>
              , Êtes-vous sûr de vouloir <b>supprimer</b> votre compte? Cette action ne
              peut être annulée.
            </>
          ),
        },
        labels: {
          password: "Mot de passe",
        },
        buttons: {
          accept: "Supprimer le compte",
          cancel: "Annuler",
        },
      },
    },
  },
  notifications: {
    title: "Notifications",
    subTitle: "Dernières",
    markAsRead: "Marquer comme lu",
    markAsUnread: "Marquer comme non lu",
    markAllAsRead: "Tout marquer comme lu",
    emptyNotificationMsg: "Aucune notification trouvée",
    footerMsg: "Voici toutes vos notifications des 30 derniers jours",
    toast: {
      success: "Mise à jour réussie",
      error: "La demande a échoué. Veuillez réessayer plus tard",
    },
  },
};
export const German: LanguageText = {
  settings: {
    changeYourDetails: "Ändern Sie Ihre Daten",
    changeYourPassword: "Ändern Sie Ihr Passwort",
    labels: {
      username: "Nutzername",
      emailAddress: "E-Mail Adresse",
      oldPassword: "Altes Passwort",
      newPassword: "Neues Passwort",
      confirmNewPassword: "Neues Passwort bestätigen",
      language: "Sprache",
    },
    buttons: {
      save: "Speichern",
      change: "Ändern",
      deleteUser: "Benutzer löschen",
    },
    toasts: {
      updateUser: "Benutzer erfolgreich aktualisiert",
      deleteUser: "Benutzer erfolgreich gelöscht",
      changePassword: "Passwort erfolgreich geändert",
      updateUserSettings: "Benutzereinstellungen erfolgreich aktualisiert",
      updateUserCheckEmail:
        "Wenn Sie Ihre E-Mail-Adresse geändert haben, überprüfen Sie bitte Ihre E-Mail, um die Änderung zu bestätigen.",
    },
    modals: {
      changeDetailsModal: {
        title: "Passwort erforderlich",
        body: {
          passwordRequired:
            "Passwort erforderlich für Änderungen von Benutzernamen und E-Mail.",
        },
        labels: {
          password: "Passwort",
        },
        buttons: {
          accept: "Einreichen",
          cancel: "Stornieren",
        },
      },
      deleteModal: {
        title: "Konto löschen",
        body: {
          phrase1: (
            <>
              , Sind Sie sicher, dass Sie Ihr Konto <b>löschen</b> möchten? Diese Aktion
              kann nicht rückgängig gemacht werden.
            </>
          ),
        },
        labels: {
          password: "Passwort",
        },
        buttons: {
          accept: "Konto löschen",
          cancel: "Abbrechen",
        },
      },
    },
  },
  notifications: {
    title: "Benachrichtigungen",
    subTitle: "Neueste",
    markAsRead: "Als gelesen markieren",
    markAsUnread: "Als ungelesen markieren",
    markAllAsRead: "Alle als gelesen markieren",
    emptyNotificationMsg: "Keine Benachrichtigungen gefunden",
    footerMsg: "Das sind alle Ihre Benachrichtigungen der letzten 30 Tage",
    toast: {
      success: "Aktualisierung erfolgreich",
      error: "Anfrage fehlgeschlagen. Bitte versuchen Sie es später noch einmal",
    },
  },
};
export const Dutch: LanguageText = {
  settings: {
    changeYourDetails: "Wijzig uw gegevens",
    changeYourPassword: "Verander uw wachtwoord",
    labels: {
      username: "Gebruikersnaam",
      emailAddress: "E-mailadres",
      oldPassword: "Oud wachtwoord",
      newPassword: "Nieuw wachtwoord",
      confirmNewPassword: "Bevestig nieuw wachtwoord",
      language: "Taal",
    },
    buttons: {
      save: "Opslaan",
      change: "Verandering",
      deleteUser: "Gebruiker verwijderen",
    },
    toasts: {
      updateUser: "Gebruiker succesvol bijgewerkt",
      deleteUser: "Gebruiker succesvol verwijderd",
      changePassword: "Wachtwoord succesvol gewijzigd",
      updateUserSettings: "Gebruikersinstellingen succesvol bijgewerkt",
      updateUserCheckEmail:
        "Als u uw e-mailadres hebt gewijzigd, controleert u alstublieft uw e-mail om de wijziging te bevestigen.",
    },
    modals: {
      changeDetailsModal: {
        title: "Wachtwoord vereist",
        body: {
          passwordRequired:
            "Wachtwoord vereist voor wijzigingen van gebruikersnaam en e-mail.",
        },
        labels: {
          password: "Wachtwoord",
        },
        buttons: {
          accept: "Indienen",
          cancel: "Annuleren",
        },
      },
      deleteModal: {
        title: "Account verwijderen",
        body: {
          phrase1: (
            <>
              , weet je zeker dat je je account wilt <b>verwijderen</b>? Deze actie kan
              niet ongedaan worden gemaakt.
            </>
          ),
        },
        labels: {
          password: "Wachtwoord",
        },
        buttons: {
          accept: "Account verwijderen",
          cancel: "Annuleren",
        },
      },
    },
  },
  notifications: {
    title: "Meldingen",
    subTitle: "Laatste",
    markAsRead: "Markeren als gelezen",
    markAsUnread: "Markeren als ongelezen",
    markAllAsRead: "Alles markeren als gelezen",
    emptyNotificationMsg: "Geen meldingen gevonden",
    footerMsg: "Dit zijn al je meldingen van de laatste 30 dagen",
    toast: {
      success: "Update succesvol",
      error: "Verzoek mislukt. Probeer het later opnieuw",
    },
  },
};
export const Spanish: LanguageText = {
  settings: {
    changeYourDetails: "Cambia tus datos",
    changeYourPassword: "Cambia tu contraseña",
    labels: {
      username: "Nombre de usuario",
      emailAddress: "Dirección de correo electrónico",
      oldPassword: "Contraseña antigua",
      newPassword: "Nueva contraseña",
      confirmNewPassword: "Confirmar nueva contraseña",
      language: "Idioma",
    },
    buttons: {
      save: "Guardar",
      change: "Cambiar",
      deleteUser: "Borrar usuario",
    },
    toasts: {
      updateUser: "Usuario actualizado correctamente",
      deleteUser: "Usuario eliminado con éxito",
      changePassword: "Contraseña cambiada con éxito",
      updateUserSettings: "Configuración de usuario actualizada correctamente",
      updateUserCheckEmail:
        "Si ha cambiado su dirección de correo electrónico, verifique su correo electrónico para confirmar el cambio.",
    },
    modals: {
      changeDetailsModal: {
        title: "Se requiere contraseña",
        body: {
          passwordRequired:
            "Se requiere contraseña para cambios de nombre de usuario y correo electrónico.",
        },
        labels: {
          password: "Contraseña",
        },
        buttons: {
          accept: "Enviar",
          cancel: "Cancelar",
        },
      },
      deleteModal: {
        title: "Eliminar cuenta",
        body: {
          phrase1: (
            <>
              , ¿estás seguro de que quieres <b>eliminar</b> tu cuenta? Esta acción no se
              puede deshacer.
            </>
          ),
        },
        labels: {
          password: "Contraseña",
        },
        buttons: {
          accept: "Eliminar cuenta",
          cancel: "Cancelar",
        },
      },
    },
  },
  notifications: {
    title: "Notificaciones",
    subTitle: "Últimas",
    markAsRead: "Marcar como leído",
    markAsUnread: "Marcar como no leído",
    markAllAsRead: "Marcar todo como leído",
    emptyNotificationMsg: "No se encontraron notificaciones",
    footerMsg: "Esas son todas tus notificaciones de los últimos 30 días",
    toast: {
      success: "Actualización exitosa",
      error: "La solicitud ha fallado. Por favor, intente nuevamente más tarde",
    },
  },
};
export const Italian: LanguageText = {
  settings: {
    changeYourDetails: "Cambia i tuoi dati",
    changeYourPassword: "Cambia la tua password",
    labels: {
      username: "Nome utente",
      emailAddress: "Indirizzo e-mail",
      oldPassword: "Vecchia password",
      newPassword: "Nuova password",
      confirmNewPassword: "Conferma la nuova password",
      language: "Lingua",
    },
    buttons: {
      save: "Salva",
      change: "Cambiamento",
      deleteUser: "Elimina utente",
    },
    toasts: {
      updateUser: "Utente aggiornato con successo",
      deleteUser: "Utente cancellato con successo",
      changePassword: "Password modificata con successo",
      updateUserSettings:
        "Le impostazioni dell'utente sono state aggiornate con successo",
      updateUserCheckEmail:
        "Se hai modificato il tuo indirizzo email, controlla la tua email per confermare la modifica.",
    },
    modals: {
      changeDetailsModal: {
        title: "Password richiesta",
        body: {
          passwordRequired: "Password richiesta per modifiche di nome utente e e-mail.",
        },
        labels: {
          password: "Password",
        },
        buttons: {
          accept: "Invia",
          cancel: "Annulla",
        },
      },
      deleteModal: {
        title: "Elimina account",
        body: {
          phrase1: (
            <>
              , sei sicuro di voler <b>cancellare</b> il tuo account? Questa azione non
              può essere annullata.
            </>
          ),
        },
        labels: {
          password: "Password",
        },
        buttons: {
          accept: "Elimina account",
          cancel: "Annulla",
        },
      },
    },
  },
  notifications: {
    title: "Notifiche",
    subTitle: "Ultime",
    markAsRead: "Segna come letto",
    markAsUnread: "Segna come non letto",
    markAllAsRead: "Segna tutto come letto",
    emptyNotificationMsg: "Nessuna notifica trovata",
    footerMsg: "Queste sono tutte le tue notifiche degli ultimi 30 giorni",
    toast: {
      success: "Aggiornamento riuscito",
      error: "La richiesta è fallita. Per favore, riprova più tardi",
    },
  },
};
export const Romanian: LanguageText = {
  settings: {
    changeYourDetails: "Schimbă-ți datele",
    changeYourPassword: "Schimbă-ți parola",
    labels: {
      username: "Nume de utilizator",
      emailAddress: "Adresa de e-mail",
      oldPassword: "Parola veche",
      newPassword: "Noua parolă",
      confirmNewPassword: "Confirmă noua parolă",
      language: "Limba",
    },
    buttons: {
      save: "Salvați",
      change: "Schimbare",
      deleteUser: "Ștergeți utilizatorul",
    },
    toasts: {
      updateUser: "Utilizator actualizat cu succes",
      deleteUser: "Utilizator șters cu succes",
      changePassword: "Parola a fost schimbată cu succes",
      updateUserSettings: "Setările utilizatorului actualizate cu succes",
      updateUserCheckEmail:
        "Dacă ați modificat adresa dvs. de e-mail, verificați-vă e-mailul pentru a confirma modificarea.",
    },
    modals: {
      changeDetailsModal: {
        title: "Parolă necesară",
        body: {
          passwordRequired:
            "Parola necesară pentru modificarea numelui de utilizator și a e-mail-ului.",
        },
        labels: {
          password: "Parolă",
        },
        buttons: {
          accept: "Trimite",
          cancel: "Anulare",
        },
      },
      deleteModal: {
        title: "Șterge contul",
        body: {
          phrase1: (
            <>
              , ești sigur că vrei să-ți <b>ștergi</b> contul? Această acțiune nu poate fi
              anulată.
            </>
          ),
        },
        labels: {
          password: "Parolă",
        },
        buttons: {
          accept: "Șterge contul",
          cancel: "Anulează",
        },
      },
    },
  },
  notifications: {
    title: "Notificări",
    subTitle: "Cele mai recente",
    markAsRead: "Marchează ca citit",
    markAsUnread: "Marchează ca necitit",
    markAllAsRead: "Marchează tot ca citit",
    emptyNotificationMsg: "Nu au fost găsite notificări",
    footerMsg: "Acestea sunt toate notificările tale din ultimele 30 de zile",
    toast: {
      success: "Actualizare reușită",
      error: "Solicitarea a eșuat. Vă rugăm să încercați din nou mai târziu",
    },
  },
};
export const Portuguese: LanguageText = {
  settings: {
    changeYourDetails: "Altere os seus dados",
    changeYourPassword: "Altere a sua palavra-chave",
    labels: {
      username: "Nome de utilizador",
      emailAddress: "Endereço de correio electrónico",
      oldPassword: "Palavra-passe antiga",
      newPassword: "Nova Palavra-passe",
      confirmNewPassword: "Confirmar nova senha",
      language: "Língua",
    },
    buttons: {
      save: "Salvar",
      change: "Mudança",
      deleteUser: "Eliminar utilizador",
    },
    toasts: {
      updateUser: "Utilizador actualizado com sucesso",
      deleteUser: "Utilizador apagado com sucesso",
      changePassword: "Senha alterada com sucesso",
      updateUserSettings: "Configurações do utilizador actualizadas com sucesso",
      updateUserCheckEmail:
        "Se alterou o seu endereço de e-mail, verifique o seu e-mail para confirmar a alteração.",
    },
    modals: {
      changeDetailsModal: {
        title: "Senha necessária",
        body: {
          passwordRequired:
            "Senha necessária para alterações de nome de utilizador e e-mail.",
        },
        labels: {
          password: "Senha",
        },
        buttons: {
          accept: "Enviar",
          cancel: "Cancelar",
        },
      },
      deleteModal: {
        title: "Eliminar conta",
        body: {
          phrase1: (
            <>
              , tem a certeza de que quer <b>apagar</b> a sua conta? Esta acção não pode
              ser desfeita.
            </>
          ),
        },
        labels: {
          password: "Senha",
        },
        buttons: {
          accept: "Eliminar conta",
          cancel: "Cancelar",
        },
      },
    },
  },
  notifications: {
    title: "Notificações",
    subTitle: "Últimas",
    markAsRead: "Marcar como lido",
    markAsUnread: "Marcar como não lido",
    markAllAsRead: "Marcar tudo como lido",
    emptyNotificationMsg: "Nenhuma notificação encontrada",
    footerMsg: "Essas são todas as suas notificações dos últimos 30 dias",
    toast: {
      success: "Actualizare reușită",
      error: "Solicitarea a eșuat. Vă rugăm să încercați din nou mai târziu",
    },
  },
};
