import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserNotification } from "../../features/user/userSlice";
import { useToggleUserNotificationReadMutation } from "../../services/userAPI";
import { convertUtcToLocalTime } from "../../utils/DateFormating";
import { useMarkAllNotificationsAsReadMutation } from "../../services/userAPI";
import { LanguageText } from "../../dictionary/UserText";
import useGetLanguage from "../../utils/useGetLanguage";
interface NotificationListProp {
  notification: UserNotification;
}

const DropdownNotification = ({ notifications }: DropdownNotificationProps) => {
  const language: LanguageText = useGetLanguage({ fileName: "UserText" });
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifying, setNotifying] = useState(true);

  const trigger = useRef<any>(null);
  const dropdown = useRef<any>(null);

  let notificationsArray: UserNotification[] = [];

  const [toggleRead, { isError, error: toggleError, data, isSuccess: isToggleSuccess }] =
    useToggleUserNotificationReadMutation();
  const [markAllNotificationsAsRead, { isError: markAllReadError }] =
    useMarkAllNotificationsAsReadMutation();

  const handleAllNotificationsAsRead = async () =>
    await markAllNotificationsAsRead({ language: language || null });

  if (notifications) {
    for (const [key, value] of Object.entries(notifications)) {
      var newTimestamp = new Date(value.timestamp).toDateString();
      var firstIndex = newTimestamp.indexOf(" ");
      var lastIndex = newTimestamp.lastIndexOf(" ");
      var formattedDate =
        newTimestamp.substring(firstIndex, lastIndex) +
        ", " +
        newTimestamp.substring(lastIndex);

      var formattedValue = {
        ...value,
        date: formattedDate,
      };
      notificationsArray.push(formattedValue);
    }

    notificationsArray.sort(
      (a: any, b: any) => Date.parse(b.timestamp) - Date.parse(a.timestamp)
    );
  }

  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  const NotificationList = ({ notification }: NotificationListProp): JSX.Element => {
    const handleToggleRead = async (
      event: React.MouseEvent<HTMLOrSVGElement>,
      notificationID: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      notificationID &&
        (await toggleRead({ notificationID: notificationID, language: language }));
    };
    const handleNotificationClick = (
      event: React.MouseEvent<HTMLLIElement>,
      notification: UserNotification
    ) => {
      const { id, content, link_id, link_info, type: notificationType } = notification;
      link_info === "device"
        ? navigate(`device/${link_id}`)
        : link_info === "dashboard"
        ? navigate(`dashboard/${link_id}`)
        : navigate(`/`);
    };

    return (
      <li
        className="flex flex-col gap-2.5 border-t border-stroke p-5 hover:bg-gray-200  transition-colors duration-200 dark:border-strokedark dark:hover:bg-meta-4 cursor-pointer"
        onClick={(event) => handleNotificationClick(event, notification)}
      >
        <div className="relative flex flex-row gap-3 items-center">
          <p className="text-sm">
            {notification.content.length > 255
              ? `${notification?.content.slice(0, 255)}...`
              : notification?.content}
          </p>
          <div className="group">
            {notification.read ? (
              <svg
                className="absolute right-0 top-1 hover:cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 24 24"
                fill="grey"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                onClick={(event) => handleToggleRead(event, notification.id)}
              >
                <circle cx="12" cy="12" r="10"></circle>
              </svg>
            ) : (
              <svg
                className="absolute right-0 top-1 hover:cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="7"
                height="7"
                viewBox="0 0 24 24"
                fill="blue"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                onClick={(event) => handleToggleRead(event, notification.id)}
              >
                <circle cx="12" cy="12" r="10"></circle>
              </svg>
            )}
            <span className="group-hover:opacity-100 transition-opacity bg-blue-500 px-1 text-xs text-white rounded-md absolute right-3 -top-5 opacity-0 m-4 p-1 mx-auto cursor-default pointer-events-none">
              {notification.read
                ? `${language?.notifications?.markAsUnread}` || `Mark as unread`
                : `${language?.notifications?.markAsRead}` || `Mark as read`}
            </span>
          </div>
        </div>
        <p className="text-xs">{convertUtcToLocalTime(notification?.timestamp)}</p>
      </li>
    );
  };

  const isAtLeastOneUnread: boolean = notificationsArray.some(
    (notification) => notification.read === false
  );
  return (
    <>
      <Link
        ref={trigger}
        onClick={() => {
          setNotifying(false);
          setDropdownOpen(!dropdownOpen);
        }}
        to="#"
        className="relative hover:text-primary inline-flex items-center"
      >
        {isAtLeastOneUnread && (
          <span className="absolute -top-2 -left-2 flex min-w-[22px] h-5 px-1 py-0.5 items-center justify-center rounded-full bg-red-500 text-xs font-semibold text-white z-10 shadow-sm border border-white transform scale-95 hover:scale-100 transition-transform duration-200">
            {notificationsArray.filter((notification) => !notification.read).length}
          </span>
        )}
        <svg
          className="relative"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
        </svg>
      </Link>

      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute right-0 mt-2.5 mr-3 flex h-90 w-75 min-w-settings flex-col rounded-md border border-stroke bg-white shadow-lg dark:border-strokedark dark:bg-boxdark sm:right-0 sm:w-80 ${
          dropdownOpen === true ? "block" : "hidden"
        }`}
      >
        <div className="relative px-4.5 py-3">
          <h4 className="text-sm font-medium text-bodydark2 px-5">
            {language?.notifications?.title || "Notifications"}
          </h4>
          <h2 className="text-xs font-small px-5 text-gray-500">
            {language?.notifications?.subTitle || "LATEST"}
          </h2>
          {isAtLeastOneUnread && (
            <button
              onClick={handleAllNotificationsAsRead}
              className="absolute bottom-3 right-3 lg:bottom-2 lg:right-2 text-[10px] hover:text-white hover:bg-blue-600 rounded-lg font-small lg:text-xs md:text-[9px] sm:text-[8px] py-2 px-2 lg:py-1.5 lg:px-3 md:py-1 md:px-2.5 sm:py-1 sm:px-2"
            >
              {language?.notifications?.markAllAsRead || "Mark all as read"}
            </button>
          )}
        </div>

        <ul className="flex flex-col h-[70vh] overflow-visible overflow-y-scroll px-5">
          {notificationsArray.length === 0
            ? `${language?.notifications?.emptyNotificationMsg}` || `no notifications`
            : notificationsArray.map((notification, i) => (
                <NotificationList key={i} notification={notification} />
              ))}
          <li>
            <div className="text-xs py-20 px-10 text-gray-500 text-center border-t border-stroke">
              <p>
                {language?.notifications?.footerMsg ||
                  "That's all your unread notifications from the last 30 days"}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

type DropdownNotificationProps = {
  notifications: Object | null;
};

export default DropdownNotification;
