import { useEffect, useState } from "react";
import { useGetDevicesQuery } from "../../services/deviceAPI";
import { IoMdSettings } from "react-icons/io";
import { ColumnDef, FilterFn } from "@tanstack/react-table";

import _ from "lodash";
import { Device } from "../../features/devices/deviceSlice";
import { useNavigate } from "react-router-dom";

import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../../dictionary/DeviceText";
import { LanguageCheck } from "../../utils/LanguageCheck";
import { useAppSelector } from "../../app/hooks";
import { selectLanguage } from "../../features/user/userSlice";
import { Table } from "../../utils/Table";
import { useGetGroupsQuery } from "../../services/groupAPI";

export default function DeviceTable() {
  const { data: devInfo, isSuccess, isLoading } = useGetDevicesQuery("");

  const {
    data: groupInfo,
    isSuccess: groupsIsSuccess,
    isLoading: groupsIsLoading,
  } = useGetGroupsQuery("");

  const navigation = useNavigate();
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const columns: ColumnDef<Device>[] = [
    {
      accessorFn: (row) => row.name,
      id: "DeviceName",
      cell: ({ row, getValue }) => (
        <div className="flex ">
          <IoMdSettings
            title="Quick Edit"
            size={16}
            className="cursor-pointer hover:fill-black"
            onClick={() => {
              navigation(`/device/${row.original?.id}`);
            }}
          />
          <div className="ml-2">{getValue() as string}</div>
        </div>
      ),
      header: language.deviceTable.tableElements.headers.deviceName,
    },
    {
      accessorFn: (row) => row.eui,
      id: "DeviceID",
      cell: ({ row, getValue }) => getValue(),
      header: language.deviceTable.tableElements.headers.deviceEUI,
    },
    {
      accessorFn: (row) =>
        _.map(Object.values(groupInfo ?? [])).find((group) => group.id === row.group_id)
          ?.name ?? "",
      id: "DeviceGroup",
      cell: ({ row, getValue }) => getValue(),
      header: language.deviceTable.tableElements.headers.group,
    },

    // {
    //   accessorFn: (row) => row,
    //   id: "status",
    //   cell: ({ row, getValue }) => {
    //     const notification: any = getValue();

    //     const formatDate = (isoString: string): string => {
    //       const date: Date = new Date(isoString);
    //       const options: Intl.DateTimeFormatOptions = {
    //         year: "numeric",
    //         month: "short",
    //         day: "numeric",
    //         hour: "2-digit",
    //         minute: "2-digit",
    //         hour12: false,
    //       };
    //       return date.toLocaleString("en-GB", options);
    //     };

    //     return (
    //       <div className="flex items-center space-x-4 p-4 w-full max-w-md">
    //         <div
    //           className={`w-2 h-2 rounded-full bg-${
    //             notification?.status?.current ? "green" : "red"
    //           }-500`}
    //         >
    //           {" "}
    //         </div>
    //         {notification?.status?.current &&
    //           (notification?.configuration?.latch_on ? (
    //             <div className="leading-none">
    //               <p className="text-xl text">
    //                 {formatDate(notification?.status?.triggered_at)}
    //               </p>
    //               <p className="ml-0.5">
    //                 {language?.general?.triggeredAt || "Triggered at"}
    //               </p>
    //             </div>
    //           ) : (
    //             <div className="leading-none">
    //               <p className="text-xl text">
    //                 {formatDate(notification?.status?.evaluated_at)}
    //               </p>
    //               <p className="ml-0.5">
    //                 {language?.general?.triggeredAt || "Triggered at"}
    //               </p>
    //             </div>
    //           ))}
    //       </div>
    //     );
    //   },
    //   header: "Status",
    // },
    {
      accessorFn: (row) => row.status.online,
      id: "DeviceStatus",
      cell: ({ row }) => {
        const lastMessageTs = row.original?.status.last_message_ts;
        if (!lastMessageTs) {
          return `${language.deviceTable.tableElements.offline} (N/A)`;
        }
        const status = row.original?.status?.online
          ? language.deviceTable.tableElements.online
          : language.deviceTable.tableElements.offline;
        return `${status} ${new Date(lastMessageTs).toLocaleString()}`;
      },
      header: language.deviceTable.tableElements.headers.deviceStatus,
    },
  ];

  let sortedDevices: Device[] = [];
  if (devInfo) {
    sortedDevices = _.sortBy(devInfo, "group_id");
  }

  var table: JSX.Element = <></>;

  table = Table(
    devInfo,
    columns,
    language.deviceTable.devices,
    language.deviceTable.tableElements.noDevicesFound,
    false,
    "DeviceGroup"
  );

  return table;
}
