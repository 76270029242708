import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  ChangeEvent,
  useImperativeHandle,
} from "react";
import { useParams } from "react-router-dom";
import { IoAddCircle } from "react-icons/io5";
import { CiCircleRemove } from "react-icons/ci";
import { RiMailSettingsLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { GetGroupUserInfo } from "../../utils/GroupNotificationUtils";
import { mapObjectToArray } from "../../utils/GroupNotificationUtils";
import { useGetGroupQuery } from "../../services/groupAPI";
import { LanguageText } from "../../dictionary/GroupNotificationText";
import useGetLanguage from "../../utils/useGetLanguage";
import { MdOutlineError } from "react-icons/md";

type user = {
  id: string;
  username: string;
  email: string;
};
export interface NotificationEmailProps {
  users?: any;
  subject?: string;
  body?: string;
  trigger?: string;
}

export interface NotificationEmailRef {
  getNotificationEmail: () => NotificationEmailProps;
  validate: () => boolean;
}

const NotificationEmail = forwardRef(
  (
    { users = [], subject = "", body = "", trigger = "" }: NotificationEmailProps,
    ref
  ): JSX.Element => {
    const language: LanguageText = useGetLanguage({
      fileName: "GroupNotificationText",
    });
    const { groupid } = useParams<{ groupid: string }>();
    const { data: group, isLoading, error } = useGetGroupQuery({ id: String(groupid) });
    const groupMembers = mapObjectToArray(group?.users || []);
    const [notificationEmail, setNotificationEmail] = useState({
      users,
      subject,
      body,
      trigger,
    });
    const [usersError, setUsersError] = useState<boolean>(false);
    const [notificationEmailSubjectError, setNotificationEmailSubjectError] =
      useState<boolean>(false);
    const [notificationEmailBodyError, setNotificationEmailBodyError] =
      useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      getNotificationEmail: () => ({
        users: notificationEmail.users,
        subject: notificationEmail.subject,
        body: notificationEmail.body,
        trigger: notificationEmail.trigger,
      }),
      validate: (): boolean => {
        let isValid: boolean = true;
        if (notificationEmail.users.length <= 0) {
          setUsersError(true);
          isValid = false;
        } else setUsersError(false);

        if (!notificationEmail.subject || notificationEmail.subject.trim() === "") {
          setNotificationEmailSubjectError(true);
          isValid = false;
        } else setNotificationEmailSubjectError(false);
        if (!notificationEmail.body || notificationEmail.body.trim() === "") {
          setNotificationEmailBodyError(true);
          isValid = false;
        } else setNotificationEmailBodyError(false);
        return isValid;
      },
    }));

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      setNotificationEmail((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });

      //console.log("Event name", name);
      if (name === "subject") setNotificationEmailSubjectError(false);
      if (name === "body") setNotificationEmailBodyError(false);
    };
    const ListUsers = (): JSX.Element => {
      const userListRef = useRef<HTMLUListElement>(null);
      const userListScrollIndicatorRef = useRef<HTMLDivElement>(null);

      useEffect(() => {
        const listElement = userListRef.current;
        const scrollIndicatorElement = userListScrollIndicatorRef.current;

        const handleScroll = () => {
          if (listElement && scrollIndicatorElement) {
            const scrollPercentage =
              (listElement.scrollTop /
                (listElement.scrollHeight - listElement.clientHeight)) *
              100;
            scrollIndicatorElement.style.width = `${scrollPercentage}%`;
          }
        };

        if (listElement) {
          listElement.addEventListener("scroll", handleScroll);
        }

        return () => {
          if (listElement) {
            listElement.removeEventListener("scroll", handleScroll);
          }
        };
      }, []);

      const removeUser = (userID: string) => {
        // Check if member with the id exists in the group members
        if (!groupMembers.some((member: any) => member.id === userID)) {
          toast.error(
            language?.messages?.toast?.general?.noMemberFound || "Member Not Found"
          );
          return;
        }
        setNotificationEmail((prev) => {
          return {
            ...prev,
            users: prev.users.filter((userid: any) => userid !== userID),
          };
        });
      };

      return (
        <>
          <label className="block text-gray-600 mb-1">Recipients</label>
          <div className="bg-white shadow-md rounded-md overflow-hidden w-1/2 mt-0">
            {notificationEmail.users.length ? (
              <>
                <ul
                  ref={userListRef}
                  className="divide-y divide-gray-200 max-h-48 overflow-y-auto"
                >
                  {notificationEmail.users
                    .slice()
                    .reverse()
                    .map((userId: any, index: any) => {
                      const { data, isLoading, error } = GetGroupUserInfo({
                        groupid: groupid || "",
                        userid: userId,
                      });
                      return (
                        <li key={userId} className="flex items-center py-2 px-4">
                          <div className="flex-1 min-w-0">
                            <h3 className="text-lg font-medium text-gray-800 truncate">
                              {data?.username}
                            </h3>
                            <p className="text-gray-600 text-base truncate">
                              {data?.email}
                            </p>
                          </div>
                          <CiCircleRemove
                            size={30}
                            className="ml-2 text-gray-600 hover:text-gray-800 flex-shrink-0 cursor-pointer"
                            onClick={() => removeUser(userId)}
                          />
                        </li>
                      );
                    })}
                </ul>
                <div ref={userListScrollIndicatorRef} className="h-1 bg-gray-300 w-full">
                  <div className="h-1 bg-blue-500"></div>
                </div>
              </>
            ) : (
              <>
                <div className="flex-1">
                  <p className="text-gray-600 text-base mt-10 p-2">
                    {language?.emailComponent?.usersList?.emptyListMsg ||
                      "No user added yet"}
                  </p>
                </div>
              </>
            )}
          </div>
          {/* {usersError && <p className="text-red-500 text-sm mt-2">{usersError}</p>} */}
          {usersError && <MdOutlineError className="text-red-500 text-lg mt-2" />}
        </>
      );
    };

    const SelectInput = (): JSX.Element => {
      const [emailSelectInput, setEmailSelectInput] = useState<string>("");
      const handleEmailSelectInputChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        value && setEmailSelectInput(value);
      };

      const addNotificationUser = (userID: string) => {
        // Check if a member with this id is in the group members
        if (!groupMembers.some((member: any) => member.id === userID)) {
          toast.error(
            language?.messages?.toast?.general?.noMemberFound || "Not a member"
          );
          return;
        }
        // Check if user has already been added
        if (notificationEmail.users.includes(userID)) {
          toast.error(
            language?.messages?.toast?.general?.duplicateMember ||
              "User already in the list"
          );
          return;
        }

        // Limit notification users to 10
        if (notificationEmail.users.length >= 10) {
          toast.error("Cannot add more than 10 recipients");
          return;
        }

        setNotificationEmail((prev) => {
          return {
            ...prev,
            users: [...prev.users, userID],
          };
        });
      };

      return (
        <>
          {/* <label className="block text-gray-600 mb-1">
            {language?.emailComponent?.usersSelect?.labels?.to || "To"}
          </label> */}
          <div className="flex items-center w-full mt-5">
            <select
              className="border border-gray-300 rounded p-2 mr-4 mb-4 w-1/2 max-w-full max-h-20 overflow-y-auto"
              name="selectInput"
              value={emailSelectInput}
              onChange={handleEmailSelectInputChange}
            >
              <option className="w-1/2 max-w-full" value="">
                {language?.emailComponent?.usersSelect?.optionTitle ||
                  "Select a recipient"}
              </option>
              {groupMembers ? (
                groupMembers.map((member: any, index: any) => {
                  const { data, isLoading, error } = GetGroupUserInfo({
                    groupid: groupid || "",
                    userid: member.id,
                  });
                  return (
                    <option
                      key={member.id}
                      value={member.id}
                      className="w-1/2 max-w-full"
                    >
                      {`${member.username} - ${data?.email}`}
                    </option>
                  );
                })
              ) : (
                <option className="w-1/2 max-w-full" value="">
                  {language?.emailComponent?.usersList?.emptyListMsg ||
                    "No user added yet"}
                </option>
              )}
            </select>
            {emailSelectInput && (
              <button
                className="ml-0 mb-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md shadow-lg transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-400"
                onClick={() => addNotificationUser(emailSelectInput)}
              >
                Add
              </button>
            )}
          </div>
        </>
      );
    };
    return (
      <>
        <div className="relative mb-6 bg-gray-100 p-10 rounded-lg mt-10">
          <RiMailSettingsLine
            size={35}
            className=" absolute top-2 left-2 cursor-pointer mb-10"
          />
          {/* <label className="block text-gray-700 font-bold mb-2">
            {language?.emailComponent?.usersSelect?.labels?.then || "Then"}
          </label> */}
          <div className="mb-4">
            {/* <span className="block mb-2">
              {language?.emailComponent?.usersSelect?.labels?.sendEmail || "Send email"}
            </span> */}
            <div className="mb-4">
              <SelectInput />
              <ListUsers />
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 mb-1">
                {language?.emailComponent?.subject?.heading || "Subject"}
              </label>
              <input
                type="text"
                name="subject"
                value={notificationEmail.subject}
                placeholder={
                  language?.emailComponent?.subject?.placeholder || "Email Subject"
                }
                className="border border-gray-300 rounded p-2 w-full"
                onChange={handleChange}
              />
              {notificationEmailSubjectError && (
                <MdOutlineError className="text-red-500 text-lg mt-2" />
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-600 mb-1">
                {language?.emailComponent?.messageTextArea?.heading || "Message"}
              </label>
              <textarea
                className="border border-gray-300 rounded p-2 w-full h-24"
                name="body"
                value={notificationEmail.body}
                placeholder={
                  language?.emailComponent?.messageTextArea?.placeholder ||
                  "Email message"
                }
                onChange={handleChange}
              >
                {notificationEmail.body}
              </textarea>
              {notificationEmailBodyError && (
                <MdOutlineError className="text-red-500 text-lg" />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default NotificationEmail;
