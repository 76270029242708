import _ from "lodash";
import { useEffect, useState } from "react";
import {
  MdAdd,
  MdDashboardCustomize,
  MdGroupAdd,
  MdGroups,
  MdSensors,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import QuickAccessCard from "../components/QuickAccessCard";
import { Dashboard, selectDashboards } from "../features/dashboards/dashboardSlice";
import { Device, selectDevices } from "../features/devices/deviceSlice";
import {
  English,
  French,
  German,
  Dutch,
  Spanish,
  Italian,
  Romanian,
  Portuguese,
} from "../dictionary/InfoHelpText";
import { LanguageCheck } from "../utils/LanguageCheck";
import { selectLanguage } from "../features/user/userSlice";
import { selectGroups } from "../features/groups/groupSlice";

function Info() {
  const stateLang = useAppSelector(selectLanguage);
  let [language, setLanguage] = useState(
    LanguageCheck(
      English,
      French,
      German,
      Dutch,
      Spanish,
      Italian,
      Romanian,
      Portuguese,
      stateLang
    )
  );

  useEffect(() => {
    setLanguage(
      LanguageCheck(
        English,
        French,
        German,
        Dutch,
        Spanish,
        Italian,
        Romanian,
        Portuguese,
        stateLang
      )
    );
  }, [stateLang]);

  const { dashboards } = useAppSelector(selectDashboards);
  const { devices } = useAppSelector(selectDevices);
  const { groups } = useAppSelector(selectGroups);

  var groupedDevices: { [key: string]: Device[] } = {};
  var groupedDashboards: { [key: string]: Dashboard[] } = {};

  _.map(dashboards, (dashboard) => {
    if (dashboard.group_id in groupedDashboards) {
      groupedDashboards[dashboard.group_id].push(dashboard);
    } else {
      groupedDashboards[dashboard.group_id] = [dashboard];
    }
  });

  groupedDevices = Object.keys(groupedDevices)
    .sort()
    .reduce((r: typeof groupedDevices, k) => ((r[k] = groupedDevices[k]), r), {});

  _.map(devices, (device) => {
    if (device.group_id in groupedDevices) {
      groupedDevices[device.group_id].push(device);
    } else {
      groupedDevices[device.group_id] = [device];
    }
  });

  groupedDashboards = Object.keys(groupedDashboards)
    .sort()
    .reduce((r: typeof groupedDashboards, k) => ((r[k] = groupedDashboards[k]), r), {});

  const TitleSection = (
    <>
      {_.isEmpty(groups) ? (
        <h1 className="text-3xl font-bold flex items-center">
          {language.info.noGroups.title}
        </h1>
      ) : _.isEmpty(dashboards) || _.isEmpty(devices) ? (
        <h1 className="text-3xl font-bold flex items-center">
          {language.info.hasGroup.title}
        </h1>
      ) : (
        <h1 className="text-3xl font-bold flex items-center">
          {language.info.quickAccess}
        </h1>
      )}
    </>
  );

  const JoinCreateGroupSection = (
    <>
      {
        <>
          <h2 className="text-xl flex items-center">{language.info.noGroups.subtitle}</h2>
          <div className="inline-flex space-x-2">
            <Link
              to={"/group/create"}
              className="group rounded border-2 flex flex-wrap content-center justify-center border-rpr-blue h-40 sm:max-w-[15rem] w-[50%]"
            >
              <MdAdd
                size={150}
                className="bg-white opacity-0 group-hover:opacity-70 absolute h-[156px] sm:max-w-[236px] w-[48%]"
              />
              <div className="bg-rpr-blue inline-flex space-x-2 text-white px-4 rounded-lg">
                <div>{language.info.noGroups.createBox.title}</div>
                <div>
                  <MdGroups size={24} />
                </div>
              </div>
              <div className="text-center">
                {language.info.noGroups.createBox.description}
              </div>
            </Link>
            <div className="rounded border-2 flex flex-wrap content-center justify-center border-rpr-blue h-40 sm:max-w-[15rem] w-[50%]">
              <div className="bg-rpr-blue inline-flex space-x-2 text-white px-4 rounded-lg">
                <div>{language.info.noGroups.joinBox.title}</div>
                <div>
                  <MdGroupAdd size={24} />
                </div>
              </div>
              <div className="text-center">
                {language.info.noGroups.joinBox.description}
              </div>
            </div>
          </div>
        </>
      }
    </>
  );

  const DashboardSection = (
    <>
      <h2 className="text-xl font-bold flex items-center">{language.info.dashboards}</h2>
      {_.isEmpty(dashboards) ? (
        <Link
          to={"/dashboard/create"}
          className="group rounded border-2 flex flex-wrap content-center justify-center border-rpr-blue h-40 sm:max-w-[15rem] w-[50%]"
        >
          <MdAdd
            size={150}
            className="bg-white opacity-0 group-hover:opacity-70 absolute h-[156px] sm:max-w-[236px] w-[45%]"
          />
          <div className="bg-rpr-blue inline-flex space-x-2 text-white px-4 rounded-lg">
            <div>{language.info.hasGroup.noDashboards.createBox.title}</div>
            <div>
              <MdDashboardCustomize size={24} />
            </div>
          </div>
          <div className="text-center">
            {language.info.hasGroup.noDashboards.createBox.description}
          </div>
        </Link>
      ) : (
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 mb-2">
          {Object.entries(groupedDashboards).map((groupDashboards) => {
            return groupDashboards[1].map((dashboard, index) => {
              return (
                <QuickAccessCard
                  key={index}
                  type="dashboard"
                  ID={dashboard.id}
                  title={dashboard.name}
                  description={dashboard.description}
                />
              );
            });
          })}
        </div>
      )}
    </>
  );

  const DeviceSection = (
    <>
      <h2 className="text-xl font-bold flex items-center">{language.info.devices}</h2>
      {_.isEmpty(devices) ? (
        <Link
          to={"/device/claim"}
          className="group rounded border-2 flex flex-wrap content-center justify-center border-rpr-blue h-40 sm:max-w-[15rem] w-[50%]"
        >
          <MdAdd
            size={150}
            className="bg-white opacity-0 group-hover:opacity-70 absolute h-[156px] sm:max-w-[236px] w-[49%]"
          />
          <div className="bg-rpr-blue inline-flex space-x-2 text-white px-4 rounded-lg">
            <div>{language.info.hasGroup.noDevices.claimBox.title}</div>
            <div>
              <MdSensors size={24} />
            </div>
          </div>
          <div className="text-center">
            {language.info.hasGroup.noDevices.claimBox.description}
          </div>
        </Link>
      ) : (
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
          {Object.entries(groupedDevices).map((groupDevices) => {
            return groupDevices[1].map((devices, index) => {
              return (
                <QuickAccessCard
                  key={index}
                  type="device"
                  ID={devices.id}
                  title={devices.name}
                  description={devices.eui}
                  lastContact={devices.status.last_message_ts}
                  isDeviceOnline={devices?.status?.online}
                />
              );
            });
          })}
        </div>
      )}
    </>
  );

  return (
    <div className="rounded p-3 bg-white shadow-md select-none h-full overflow-y-scroll scrollbar-thin">
      {TitleSection}
      {_.isEmpty(groups) ? (
        JoinCreateGroupSection
      ) : (
        <>
          {DashboardSection}
          <hr />
          {DeviceSection}
        </>
      )}
    </div>
  );
}

export default Info;
